import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PME = () => {
  return (
    <Layout>
      <Seo
        title="Empresas y Comercios - BIKESAFE"
        description="Con el aumento del uso de la bicicleta, ofrecer un aparcamiento para bicicletas seguro y cómodo es una decisión inteligente para negocios y comercios. Mejore la experiencia de sus clientes y empleados con nuestras soluciones de aparcabicicletas."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Empresas y Comercios</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <h3>Bueno para las empresas y el planeta</h3>
            <p className="flow-text">
              Con el aumento del uso de la bicicleta y la atención puesta en
              reducir las emisiones, cada vez más personas optan por la
              bicicleta como medio de transporte. Esto ha llevado a un
              crecimiento en el sector del ciclismo y a una mayor necesidad de
              aparcamientos para bicicletas en negocios y comercios. Ofrecer un
              aparcamiento seguro y cómodo se ha convertido en una decisión
              estratégica para cualquier empresa.
            </p>
            <p className="flow-text">
              Al ofrecer aparcabicicletas seguros para clientes y empleados, su
              empresa no solo contribuye al medio ambiente, sino que también
              mejora su imagen como negocio sostenible. Incluso en sectores no
              directamente relacionados con el ciclismo, los beneficios son
              evidentes: una clientela leal, un aumento potencial en ventas y
              una mejor experiencia para todos. Los ciclistas y los no ciclistas
              apreciarán el compromiso ecológico y la conveniencia de un lugar
              seguro para estacionar sus bicicletas.
            </p>
            <p className="flow-text">
              Un aparcamiento para bicicletas bien diseñado también evita
              conflictos con peatones y vehículos, manteniendo las bicicletas
              alejadas de barandillas y señales de tráfico, y garantizando un
              acceso libre y seguro a sus instalaciones.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/pme.jpg"
              className="shadow mt-5 mb-5 fluid rounded"
            />
            <p>
              <em className="flow-text">
                "El nuevo diseño está funcionando muy bien. Gracias por su
                ayuda."
                <strong>
                  {" "}
                  Simon Flack, Cycleops (propietario de un comercio), Kent
                </strong>
                .
              </em>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PME;
